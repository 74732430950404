import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./context/authContext";
import { PostsProvider } from "./context/postsContext";
import { HelmetProvider } from "react-helmet-async";
// Google Analytics
import ReactGA from "react-ga4";

ReactGA.initialize("G-K1J4C8N472");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <AuthContextProvider>
      <PostsProvider>
        <App />
      </PostsProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </HelmetProvider>
);
