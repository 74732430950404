import { createContext, useEffect, useState } from "react";
import { api } from '../api/api.js';

export const AuthContext = createContext();

export const AuthContextProvider = ({children})=>{
    const [currentUser,setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    const [expTimeStamp, setExpTimeStamp] = useState(JSON.parse(localStorage.getItem("exptimestamp")) || null);

    const login=async(inputs) =>{
        const res = await api.post("/api/auth/login", inputs, {
            withCredentials:true,
        });
        const {expirationTimestamp, ...data}=res.data;
        setExpTimeStamp(expirationTimestamp)
        setCurrentUser(data)
    };

    const logout=async(inputs) =>{
        await api.post("/api/auth/logout");
        setCurrentUser(null)
    };

    useEffect(()=>{
        localStorage.setItem("user",JSON.stringify(currentUser));
        localStorage.setItem("exptimestamp", JSON.stringify(expTimeStamp))
    },[currentUser]);

    return (
        <AuthContext.Provider value={{currentUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}