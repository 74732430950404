import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Paper,
  useTheme
} from "@mui/material";
import { tokens } from "../theme";
import { Link } from 'react-router-dom';

const BlogCard = ({ post }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const imageFolder = process.env.REACT_APP_IMAGE_FOLDER;
  return (
    <Paper elevation={1} square={false}>
      <Card
        sx={{
          height: "410px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Link className="link" to={`/magazine/${post.cat}/${post.slug}`}>
          <CardMedia
            component="img"
            height="200"
            image={`../${imageFolder}/${post.img}`}
            alt={post.title}
            sx={{
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        </Link>
        <CardContent>
          <Link className="link" to={`/magazine/${post.cat}/${post.slug}`}>
            <Typography 
            variant="h4"
            className="iranSans"
            align = "justify"
            gutterBottom
              sx={{
                height: "60px",
              }}
            >
              {post.title}
            </Typography>
          </Link>
          <Typography
          align = "justify"
          className="iranSans"
            sx={{
              height: "60px",
              color: "text.secondary",
            }}
          >
            {post.shortdesc}
            </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <Link className="link" to={`/magazine/${post.cat}/${post.slug}`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
              }}
              variant="contained"
              className="Button"
            >
              بیشتر بخوانید
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Paper>
  );
};
export default BlogCard;
