import React from "react";
import TokenExpirationChecker from "./utils/TokenExpirationChecker";
import { MotionLazy } from "./components/animate/motion-lazy";
import router from "./routes";

// React Router
import { RouterProvider } from "react-router-dom";

// Cash Provider
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

// Style
import "./style.scss";

// Material-UI
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

// Create RTL cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MotionLazy>
            <TokenExpirationChecker />
            <div className="app">
              <div className="container">
                <RouterProvider router={router} />
              </div>
            </div>
          </MotionLazy>
        </ThemeProvider>
      </CacheProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
