import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { Outlet } from "react-router-dom/dist/umd/react-router-dom.development";
import withAuth from '../utils/withAuth';

//Loadable Components
import loadable from "@loadable/component";

// Components
import Navbar from "../components/Navbar";
import Topbar from "../components/Topbar";

// Pages
import Home from "../pages/Home";
import Register from "../pages/Register";
import Login from "../pages/Login";

//Loadable components
const Sidebar = loadable(() => import("../pages/dashboard/global/Sidebar"));
const Footer = loadable(() => import("../components/Footer"));

//Loadable pages
const Test = loadable(() => import("../pages/Test"));
const Single = loadable(() => import("../pages/Single"));
const LoadableWrite = loadable(() => import("../pages/Write"));
const Write = withAuth(LoadableWrite);
const SearchResults = loadable(() => import("../pages/SearchResults"))
const Dashboard = loadable(() => import("../pages/dashboard"));
const PostsList = loadable(() => import("../pages/dashboard/postslist"));
const Bar = loadable(() => import("../pages/dashboard/Bar"));
const Calendar = loadable(() => import("../pages/dashboard/calendar"));
const Geography = loadable(() => import("../pages/dashboard/geography"));
const FAQ = loadable(() => import("../pages/dashboard/faq"));
const Pie = loadable(() => import("../pages/dashboard/Pie"));
const Line = loadable(() => import("../pages/dashboard/line"));
const Contacts = loadable(() => import("../pages/dashboard/contacts"));
const Invoices = loadable(() => import("../pages/dashboard/invoices"));
const Team = loadable(() => import("../pages/dashboard/team"));
const Form = loadable(() => import("../pages/dashboard/form"));
const Error404 = loadable(() => import("../pages/404"));

const Layout = () => {
  return (
    <div>
      <Topbar />
      <Navbar />
      <main style={{ minHeight: "calc(100vh - 200px)" }}>
        <Outlet />
      </main>
      <Suspense fallback={<h1>در حال بارگذاری</h1>}>
        <Footer />
      </Suspense>
    </div>
  );
};

const DashboardLayout = () => {
  return (
    <div>
      <div className="dashboard">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dashboardbody">
          <Topbar />
          <Suspense fallback={<h1>در حال بارگذاری</h1>}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/test",
        element: <Test />,
      },
      {
        path: "/magazine/:cat/:slug",
        element: <Single />,
      },
      {
        path: "/write",
        element: <Write />,
      },
      {
        path:"/results",
        element: <SearchResults/>,
      }
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/team",
        element: <Team />,
      },
      {
        path: "/dashboard/invoices",
        element: <Invoices />,
      },
      {
        path: "/dashboard/contacts",
        element: <Contacts />,
      },
      {
        path: "/dashboard/form",
        element: <Form />,
      },
      {
        path: "/dashboard/line",
        element: <Line />,
      },
      {
        path: "/dashboard/pie",
        element: <Pie />,
      },
      {
        path: "/dashboard/faq",
        element: <FAQ />,
      },
      {
        path: "/dashboard/geography",
        element: <Geography />,
      },
      {
        path: "/dashboard/calendar",
        element: <Calendar />,
      },
      {
        path: "/dashboard/bar",
        element: <Bar />,
      },
      {
        path: "/dashboard/postslist",
        element: <PostsList />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },

  {
    path: "*",
    element: <Error404 />,
  },
]);
export default router;
