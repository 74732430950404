import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { Helmet } from "react-helmet-async";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  Paper,
} from "@mui/material";

const clientId =
  "1016201016280-s96r831l2rgmhdtpq9e6h6p0jpmc2edt.apps.googleusercontent.com";

const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    currentdate: Date.now(),
  });

  const [err, setError] = useState(null);

  const navigate = useNavigate(); // Hook for navigation

  const { login } = useContext(AuthContext); // Accessing login function from AuthContext

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(inputs);
      navigate("/");
    } catch (err) {
      setError(err.response.data);
    }
  };

  const onSuccess = (res) => {
    console.log("Loging sucess! Current user:", res.profileObj);
  };

  const onFailure = (res) => {
    console.log("Loging Failed res:", res);
  };

  useEffect(() => {
    function start() {
      gapi.auth2.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  const handleLogin = async () => {
    try {
      window.open("http://localhost:8800/auth/linkedin", "_self");
    } catch (error) {
      console.error("Error logging in with LinkedIn:", error);
    }
  };
  // var accessToken=gapi.auth.getToken().access_token;
  // console.log(accessToken);
  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
    >
      <Typography 
      variant="h3"
      className="iranSans"
      sx={{ maxWidth: 480, textAlign: "center" }}>
        خوش آمدید
      </Typography>

      <Box
        component="img"
        alt="auth"
        src="../assets/illustrations/illustration_dashboard.png"
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  );

  const renderContent = (
    <Paper>

    <Stack
      spacing={2}
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      <Typography 
      alignSelf="center"
      className="iranSans"
      variant="h2">ورود کاربر</Typography>
      <div id="signInButton"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "max-content",
      }}
      >
        <GoogleLogin
          clientId="clientId"
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        />
      </div>

      <Button color="secondary" onClick={handleLogin}>
        Login with LinkedIn
      </Button>

      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&:before, :after': {
            borderTopStyle: 'dashed',
          },
        }}
        >
        یا
      </Divider>
        
      <form>
        <Stack spacing={2}>
          <TextField
            label="نام کاربری" // Label displays above the input field
            id="username" // ID helps associating form data with state
            name="username"
            size="small" // Change size if required
            onChange={handleChange} // Pass handleChange function as event handler
            color="secondary"
            dir="ltr"
          />
          <TextField
            label="رمز عبور" // Display label above the input field
            type="password" // Set type='password' for hiding characters as they are typed
            id="password" // Assign unique identifier for the password field
            name="password"
            size="small" // Adjust size if necessary
            onChange={handleChange} // Keep the same onChange handler
            color="secondary"
            dir="ltr"
            />
          <Button 
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleSubmit}>
            ورود
          </Button>
          {err && <p>{err}</p>}
          <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "max-content",
          }}
          ><Typography>
{"  "}
             ثبت نام نکرده اید؟  
          </Typography>
            <Link to="/register"><Typography>{"  "}ثبت نام{"  "}</Typography></Link>
          </span>
        </Stack>
      </form>
    </Stack>
            </Paper>
  );

  return (
    <div>
      <Helmet>
        <title> تولی هاب: ورود کاربر</title>
      </Helmet>
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: "100vh",
        }}
      >
        {renderSection}
        {renderContent}
      </Stack>
    </div>
  );
};

export default Login;
