import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../api/api.js";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Helmet } from 'react-helmet-async';
import BlogCard from "../components/BlogCard.jsx";

const Home = () => {
  const [posts, setPosts] = useState([]);

  const cat = useLocation().search; // Get query string from the URL

  // This useEffect hook is responsible for fetching posts data from the API endpoint when cat changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/posts${cat}`);
        const filteredPosts = res.data.filter(post => post.draft === 0);
        setPosts(filteredPosts.reverse());
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  // const getText = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent;
  // };

  return (
    <>
      <Helmet>
        <title>تولی هاب، پلی برای تحقق ایده‌های ناب</title>
        <meta
      name="description"
      content="وبسایتی برای علاقه‌مندان و متخصصین فعال در حوزه های مختلف ساخت و تولید و خرید تجهیزات صنعتی"
    />
        <link rel="canonical" href="https://www.tolihub.ir" />
      </Helmet>
    <div className="home">
      <Grid container spacing={2} m={2}>
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post.id}>
            <BlogCard post={post} />
          </Grid>
        ))}
      </Grid>
    </div>
    </>
  );
};

export default Home;