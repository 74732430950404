import { createContext, useEffect, useState } from "react";
import { api } from '../api/api.js';

const PostsContext = createContext();


const PostsProvider = ({ children }) => {
    const [posts, setPosts] = useState([]);

    const fetchData = async () => {
        try {
          const res = await api.get(`/api/posts`);
          setPosts(res.data.reverse());
        } catch (err) {
          console.log(err);
        }
      };
      
useEffect(() => {
   
    fetchData(); // Fetch data when the component mounts
  }, []); // Empty dependency array ensures it runs only once

  return (
    <PostsContext.Provider value={{ posts, fetchData }}>
      {children}
    </PostsContext.Provider>
  );
};

export { PostsContext, PostsProvider };