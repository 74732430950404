// withAuth.js
import React, { useContext } from 'react';
import { AuthContext } from '../context/authContext'; // Adjust the import path as necessary
//Loadable Components
import loadable from "@loadable/component";
const Error403 = loadable(() => import("../pages/403"));

const withAuth = (Component) => {
  const AuthWrapper = (props) => {
    const { currentUser } = useContext(AuthContext);

    return currentUser?.isAdmin ? <Component {...props}/> : <Error403 />;
  };

  return AuthWrapper;
};
export default withAuth;