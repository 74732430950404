import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import Snackbar from "@mui/material/Snackbar";

const TokenExpirationChecker = () => {
  const { logout } = useContext(AuthContext);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const expirationTime = localStorage.getItem("exptimestamp");
      const currentTime = Math.floor(Date.now() / 1000);

      if (expirationTime && currentTime >= expirationTime) {
        // Token has expired
        setShowSnackbar(true);
        // Schedule logout after 5 seconds
        setTimeout(() => {
          localStorage.removeItem("exptimestamp");
          logout();
        }, 5000);
      } else if (expirationTime) {
        // Token is still valid, schedule next check
        const timeUntilExpiration = parseInt(expirationTime, 10) - currentTime;
        setTimeout(checkTokenExpiration, timeUntilExpiration * 1000);
      }
    };

    checkTokenExpiration();

    // No need for interval now, as we're using setTimeout for precise timing

    return () => {
      // Clean up any pending timeouts if component unmounts
      clearTimeout();
    };
  }, [logout]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
      message="Your session is expiring. You will be logged out shortly."
    />
  );
};

export default TokenExpirationChecker;
